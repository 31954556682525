import { Avatar, Paper, Typography, Box, Button } from "@mui/material";
import KeyOffIcon from "@mui/icons-material/KeyOff";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import TimerOffIcon from "@mui/icons-material/TimerOff";
import CookiesServices from "../../services/CookiesServices";

import AuthServices from "../../services/AuthServices";
export default function SessionExpired() {
  useEffect(() => {
    logoout();

    CookiesServices.remove("user");
    CookiesServices.remove("access-token");
    CookiesServices.remove("data");
    CookiesServices.destroy();
  }, []);

  const logoout = async () => {
    await AuthServices.logout();
  };
  return (
    <Paper
      sx={{
        m: 2,
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      elevation={0}
    >
      <Avatar sx={{ width: 70, height: 70 }}>
        <TimerOffIcon
          sx={{ width: 60, height: 60, color: "red" }}
        ></TimerOffIcon>
      </Avatar>
      <Typography variant="h6" gutterBottom>
        Session Expired
      </Typography>
      <Typography>
        Please click here to login <Link to="/login">Sign In</Link>
      </Typography>
    </Paper>
  );
}
