import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Create a theme instance.
const theme = createTheme({
  MuiInputBase: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        backgroundColor: "white",
        // add variant styles like so
        "&.Mui-disabled": {
          backgroundColor: "#cccccc",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontWeightBold: 400,
  },
  palette: {
    primary: {
      main: "#303F9F",
    },
    secondary: {
      main: "#FF5252",
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
