import {
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import * as React from "react";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import DownloadIcon from "@mui/icons-material/Download";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BookingServices from "../../services/BookingServices";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const columns = [
  { id: "id", label: "Sno", minWidth: 50 },
  { id: "bookingid", label: "Booking ID", minWidth: 150 },
  { id: "hostname", label: "Host Name", minWidth: 150 },
  { id: "customername", label: "Customer Name", minWidth: 150 },
  { id: "vehiclenumber", label: "Vehicle No", minWidth: 125 },
  { id: "vehiclemake", label: "Make", minWidth: 110 },
  {
    id: "vehiclemodel",
    label: "Model",
    minWidth: 100,
  },
  { id: "plannedstartdatetime", label: "Start Date time", minWidth: 170 },
  { id: "plannedenddatetime", label: "End Date time", minWidth: 170 },
  { id: "bookedhours", label: "Booked Hrs", minWidth: 120 },
  { id: "basefare", label: "Base Fare", minWidth: 100 },
  {
    id: "protectionplanamount",
    label: "Protection Plan",
    minWidth: 180,
  },
  { id: "platformcharges", label: "Platform Charges", minWidth: 150 },
  { id: "offercode", label: "Offer Code", minWidth: 100 },
  { id: "gst", label: "GST ", minWidth: 100 },
  { id: "total", label: "Total", minWidth: 150 },
  { id: "extrahrstravel", label: "Extra Hrs Travelled", minWidth: 180 },
  { id: "extrahrsvalue", label: "Extra Charges", minWidth: 150 },
  { id: "extragst", label: "Extra GST", minWidth: 150 },
  { id: "grandtotal", label: "Grand Total", minWidth: 150 },
  { id: "paymentstatus", label: "Payment Status", minWidth: 150 },
  { id: "bookingstatus", label: "Booking Status", minWidth: 150 },
  // { id: "leaseagreementurl", label: "Lease Agreement", minWidth: 150 },
  { id: "action", label: "Action", minWidth: 100 },
];

const PendingBookings = () => {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [dialogImages, setDialogImages] = useState([]);
  const [dialogTitle, setDialogTitle] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedOption("");
  };

  const getMenuItems = (bookingstatusid) => {
    console.log("Booking Status ID=>", bookingstatusid);
    return (
      <>
        <MenuItem onClick={() => handleMenuItemClick("PR")}>
          Payment Received
        </MenuItem>
      </>
    );
  };

  useEffect(() => {
    getPendingBookings();
  }, []);

  const getPendingBookings = async () => {
    let bookingData = await BookingServices.getPendingBookings();
    console.log("Booking Data", bookingData);
    if (bookingData.status == true) {
      bookingData.result.forEach((element) => {
        element.plannedstartdatetime = new Date(
          element.plannedstartdatetime
        ).toLocaleString();
        element.plannedenddatetime = new Date(
          element.plannedenddatetime
        ).toLocaleString();
        element.leaseagreementurl = (
          <a href={element.leaseagreementurl} download>
            <DownloadIcon />
          </a>
        );
        element.action = (
          <MoreVertIcon onClick={(event) => handleClick(event, element)} />
        );
      });
      setRows(bookingData.result);
    }
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const clearSelectedRow = () => {
    setSelectedRow(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGetPrePostCheckImages = async (selectedRow, type) => {
    console.log("Selected Row in GetPreCheckImages: " + selectedRow);
    if (selectedRow) {
      console.log(
        "Selected Row: in GetPreCheckImages> " + JSON.stringify(selectedRow)
      );
      try {
        const response = await BookingServices.getPrePostCheckImages(
          selectedRow.id,
          type
        );
        console.log("API Response>:", response);
        if (response.status == true) {
          console.log("Images:", response.result);
          setDialogImages(response.result);
          setDialogTitle(
            type +
              " Images - " +
              selectedRow.vehiclenumber +
              " - " +
              selectedRow.vehiclemake +
              " " +
              selectedRow.vehiclemodel +
              " - " +
              selectedRow.vehiclecolor
          );
          setImageDialogOpen(true);
        } else {
          console.log("Error Getting Images");
        }
      } catch (error) {
        console.error("Error Getting Images:", error);
      }
    } else {
      console.log("No row selected");
    }
    handleClose();
  };

  const handleOptionSelect = async (option, selectedRow) => {
    setDialogOpen(false);

    console.log("Selected Option: in HandleOptionSelect: " + option);

    console.log("Selected Rows in HandleOpen> " + JSON.stringify(selectedRow));
    if (selectedRow) {
      try {
        const response = await BookingServices.sendLeaseAgreement(
          selectedRow.id,
          option
        );
        console.log("API Response:", response);
        if (response.status == true) {
          alert("Lease Agreement Sent Successfully");
        } else {
          alert("Error sending Lease Agreement");
        }
      } catch (error) {
        console.error("Error sending lease agreement:", error);
      }
    } else {
      console.log("No row selected");
    }
    handleClose();
  };

  const handleMenuItemClick = (action) => {
    console.log("Selected Row on Menu Click:" + JSON.stringify(selectedRow));
    console.log("Action>" + action);
    handleClose();
    if (action == "PR") {
      setConfirmDialogOpen(true);
    }

    // clearSelectedRow();
  };

  const handleConfirmDialogClose = () => {
    handleClose();
    setConfirmDialogOpen(false);
    clearSelectedRow();
  };

  const handleConfirmPaymentReceived = async () => {
    console.log(
      "Selected Row on Handle Confirm Payment:" + JSON.stringify(selectedRow)
    );
    console.log("Update Payment confirmation" + selectedRow.id);
    //handleClose();
    // Call the API here
    try {
      // Add your update logic here
      const data = {
        bookingid: selectedRow.id,
      };

      const response = await BookingServices.updateCashPayment(data);
      console.log("API Response=>:", response);
      if (response.status == true) {
        alert("Cash Payment confirmed successfully");
      } else {
        alert("Error confirming payment");
      }
    } catch (error) {
      console.error("Error confirming payment:", error);
    }
    setConfirmDialogOpen(false);
    //clearSelectedRow();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    columns.some((column) =>
      row[column.id]
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  );

  const renderProfileImage = (url, name) => {
    if (url) {
      return (
        <img
          src={
            "https://objectstorage.ap-hyderabad-1.oraclecloud.com/p/hZPWPOckWcDMqlptYrGkeqlmBDOoJmJBkmjeTMAUqKhePWPP6pJFX_iKs2GCAQ1s/n/axhsu9endsms/b/testbharatcars/o/bcp-images" +
            url
          }
          alt="Profile"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      );
    } else {
      return (
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            color: "#fff",
          }}
        >
          {name ? name.charAt(0).toUpperCase() : ""}
        </div>
      );
    }
  };

  const handleLinkClick = (type, id) => {
    console.log("Clicked" + type + "ID:" + id);
    const apiUrl = `http://10.2.2.144:3000/api/v1/get${type}Image/${id}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setImageUrl(data.imageUrl);
        setPopupOpen(true);
      });
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    setImageUrl("");
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false);
    setDialogImages([]);
    setDialogTitle("");
  };

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          padding: 2,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" id="tableTitle" component="div">
            Cash Bookings
          </Typography>
          <TextField
            id="standard-basic"
            label="Search"
            variant="filled"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ height: "50px" }} // Decrease the height of the TextField
            slotProps={{ input: { style: { height: "50px" } } }} // Ensure the input field height is also decreased
          />
        </div>

        <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
          <TableContainer sx={{ maxHeight: 460 }}>
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "#f4f4f4",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No Cash Bookings Available
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "profile" ? (
                                  renderProfileImage(
                                    row.profilePicture,
                                    row.name
                                  )
                                ) : column.id === "aadhaar" ||
                                  column.id === "dl" ? (
                                  <a
                                    href="#"
                                    onClick={() =>
                                      handleLinkClick(column.id, row.id)
                                    }
                                  >
                                    {value}
                                  </a>
                                ) : column.format &&
                                  typeof value === "number" ? (
                                  column.format(value)
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <div>{getMenuItems(selectedRow?.bookingstatusid)}</div>
        </Menu>
      </Paper>

      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogTitle>Confirm Payment</DialogTitle>
        <DialogContent>Are you sure you received cash payment?</DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose}>No</Button>
          <Button onClick={handleConfirmPaymentReceived} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PendingBookings;
