import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect } from "react";
import BookingServices from "../../services/BookingServices";

const ProtectionPlans = () => {
  const [header, setHeader] = useState("");
  const [description, setDescription] = useState("");
  const [additionalBenefits, setAdditionalBenefits] = useState([]);
  const [displayPlans, setDisplayPlans] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [selectedRow1, setSelectedRow1] = useState(null);

  const getAllProtectionPlans = async () => {
    let plansData = await BookingServices.getAllProtectionPlans();
    if (plansData.status == true) {
      setHeader(plansData.result[0].heading);
      setDescription(plansData.result[0].description);
      setAdditionalBenefits(plansData.result[0].additionalbenefits);
      setDisplayPlans(plansData.result[0].plans);
    }
  };

  useEffect(() => {
    getAllProtectionPlans();
  }, []);
  const handleClick = (id, event) => {
    if (event && event.currentTarget) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
    setSelectedRow(id);
  };
  const handleClick2 = (id, event) => {
    if (event && event.currentTarget) {
      setAnchorEl1(event.currentTarget);
    } else {
      setAnchorEl1(null);
    }
    setSelectedRow1(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const handleClose2 = () => {
    setAnchorEl1(null);
    setSelectedRow1(null);
  };
  const handleMenuItemClick = (action) => {};
  const handleHeaderChange = (e) => {
    setHeader(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleUpdateHeader = () => {
    // API call to update header
    console.log("Header updated:", header);
  };

  const handleUpdateDescription = () => {
    // API call to update description
    console.log("Description updated:", description);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleActionClick = (id) => {
    // API call to perform action

    console.log("Action clicked for ID:", id);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2>Protection Plans</h2>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Header"
              value={header}
              onChange={handleHeaderChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleUpdateHeader}>
                    <UpdateIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              value={description}
              onChange={handleDescriptionChange}
              fullWidth
              multiline
              rows={4}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleUpdateDescription}>
                    <UpdateIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Additional Benefits" />
          <Tab label="Display Plans" />
        </Tabs>
        {tabValue === 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>URL</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {additionalBenefits.map((benefit) => (
                <TableRow key={benefit.id}>
                  <TableCell>{benefit.url}</TableCell>
                  <TableCell>{benefit.title}</TableCell>
                  <TableCell>{benefit.description}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(event) => handleClick(benefit.id, event)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {tabValue === 1 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Details</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayPlans.map((plan) => (
                <TableRow key={plan.id}>
                  <TableCell>{plan.id}</TableCell>
                  <TableCell>{plan.name}</TableCell>
                  <TableCell>{plan.details}</TableCell>
                  <TableCell>{plan.value}</TableCell>
                  <TableCell>{plan.url}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(event) => handleClick2(plan.id, event)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Grid>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleMenuItemClick("GenerateOtp")}>
          Assing Another Car
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("SendViaWhatsApp")}>
          Cancel Booking
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorEl1}
        open={Boolean(anchorEl1)}
        onClose={handleClose2}
      >
        <MenuItem onClick={() => handleMenuItemClick("GenerateOtp")}>
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("SendViaWhatsApp")}>
          Disable
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("SendViaWhatsApp")}>
          Remove
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default ProtectionPlans;
