import axios from "axios";

import { apiEndpoint, Authorization } from "../configuration";
import CookiesServices from "../services/CookiesServices";

axios.defaults.withCredentials = true;

const http = axios.create({
  baseURL: apiEndpoint,
  withCredentials: true,
});

export const setupInterceptors = (navigate) => {
  http.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      config.withCredentials = true;
      let headers = new Headers();

      headers.append("Accept", "application/json");
      //    headers.append('Access-Control-Allow-Origin', 'http://127.0.0.1:3001');
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("GET", "POST", "OPTIONS");
      let token = CookiesServices.get("token");
      console.log('CookiesServices.get("token")>', token);
      headers.append("Authorization", `Bearer ${token}`);
      config.headers.set("Authorization", `Bearer ${token}`);

      if (config.url == "/api/documents/upload") {
        config.headers.set("Content-Type", "multipart/form-data");
      } else {
        config.headers.set("Content-Type", "application/json");
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  http.interceptors.response.use(
    (res) => {
      // success
      return res;
    },
    (err) => {
      console.log("err", err);

      const { code } = err;
      if (code == "ERR_NETWORK") {
        console.log("network Error");
        alert("Network Error");
      }
      const { status } = err.response;

      if (status === 401) {
        // here we have access of the useHistory() from current Router
        // navigate("/sessiontimeout");
      }

      return Promise.reject(err);
    }
  );
};

export default http;
