import React, { useEffect, useState } from "react";
import {
  requestNotificationPermission,
  onMessageListener,
} from "../../services/NotificationService";
import { Snackbar, Alert, Button } from "@mui/material";
import AuthServices from "../../services/AuthServices";
import cookiesService from "../../services/CookiesServices";

const NotificationComponent = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [open, setOpen] = useState(false);
  const [audio] = useState(new Audio("/ns.wav?" + new Date().getTime()));
  const [isSoundAllowed, setIsSoundAllowed] = useState(false);

  const handleUpdateToken = async (token) => {
    if (cookiesService.get("user") == null) return;
    console.log("User Id:=>", cookiesService.get("user").id);

    let data = await AuthServices.UpdateUserToken({
      token: token,
      id: cookiesService.get("user").id,
    });
    console.log("\nResponse data=>", data);
  };

  const initializeOnMessageListener = () => {
    onMessageListener()
      .then((payload) => {
        console.log("Payload data:=>", payload);
        console.log("PushNotification Title:=>" + payload.notification.title);
        console.log("PushNotification Body:=>" + payload.notification.body);

        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
        console.log("Open Status" + open);
        setOpen(true);
        if (isSoundAllowed) {
          audio.play().catch((error) => {
            console.error("Failed to play sound: ", error);
          });
        }
      })
      .catch((error) => {
        console.error("Failed to receive message: ", error);
      });
  };

  useEffect(() => {
    console.log(
      "NotificationComponent=> Calling Request Notification Permission "
    );
    requestNotificationPermission().then((token) => {
      console.log("Device Token:=>", token);
      handleUpdateToken(token);
    });

    initializeOnMessageListener();

    navigator.serviceWorker.addEventListener("message", (event) => {
      const payload = event.data.payload;
      console.log("Payload data from service worker:=>", payload);

      if (payload != null && payload.notification != null) {
        console.log("PushNotification Title:=>" + payload.notification.title);
        console.log("PushNotification Body:=>" + payload.notification.body);
      }

      setNotification({
        title: payload.title,
        body: payload.body,
      });
      setOpen(true);
      if (isSoundAllowed) {
        audio.play().catch((error) => {
          console.error("Failed to play sound: ", error);
        });
      }
    });
  }, []);

  const handleAllowSound = () => {
    setIsSoundAllowed(true);
  };

  const handleUserInteraction = () => {
    audio.play().catch((error) => {
      console.error("Failed to play sound: ", error);
    });
    handleAllowSound();
  };

  const handleClose = () => {
    console.log("In Closing of Alert Box!!!!##################");
    setOpen(false);
    audio.pause();
    audio.currentTime = 0; // Reset audio to the beginning
  };

  return (
    <>
      {!isSoundAllowed && (
        <Button onClick={handleUserInteraction}>Enable Sound</Button>
      )}
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
          {notification.title}: {notification.body}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NotificationComponent;
