import {
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import VehicleServices from "../../services/VehicleServices";

import MoreVertIcon from "@mui/icons-material/MoreVert";

const columns = [
  { id: "id", label: "Sno", minWidth: 50 },
  { id: "hostname", label: "Host", minWidth: 200 },
  { id: "vehicleno", label: "Vehicle", minWidth: 100 },
  { id: "make", label: "Make", minWidth: 150 },
  { id: "model", label: "Model", minWidth: 150 },
  { id: "color", label: "Color", minWidth: 200 },

  { id: "category", label: "Category", minWidth: 110 },
  {
    id: "trasmission",
    label: "Transmission",
    minWidth: 100,
  },
  { id: "registrationyear", label: "Year", minWidth: 70 },

  { id: "vehiclekms", label: "Kms", minWidth: 90 },
  { id: "rating", label: "Rating", minWidth: 150 },
  { id: "vehicleseatingcapacity", label: "Seating Capacity", minWidth: 150 },

  { id: "speed", label: "Speed", minWidth: 100 },
  { id: "direction", label: "Direction", minWidth: 100 },
  { id: "ignitionstatus", label: "Ignition", minWidth: 100 },
  { id: "gpsdatetime", label: "GPS Date Time", minWidth: 250 },

  { id: "isActive", label: "isActive", minWidth: 100 },
  { id: "isgpsenable", label: "isGpsEnabled", minWidth: 100 },
  { id: "gpsuniqueid", label: "GPS Unit ID", minWidth: 250 },
  { id: "latitude", label: "Latitude", minWidth: 100 },
  {
    id: "longitude",
    label: "Longitude",
    minWidth: 100,
  },
  { id: "location", label: "Locality", minWidth: 550 },

  { id: "action", label: "Action", minWidth: 100 },
];

const LiveVehicles = () => {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [dialogQuestion, setDialogQuestion] = useState("");

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const getAllVehiclesDetails = async () => {
    let vehicleData = await VehicleServices.getActiveLiveVehiclesList();
    if (vehicleData.status == true) {
      vehicleData.result.forEach((element) => {
        if (element.isactive == 1) {
          element.isActive = "Active";
        } else {
          element.isActive = "Deactivated";
        }
        if (element.ignitionstatus == 1) {
          element.ignitionstatus = "ON";
        } else {
          element.ignitionstatus = "OFF";
        }
        if (element.isgpsenable == 1) {
          element.isgpsenable = "Enabled";
        } else {
          element.isgpsenable = "Disabled";
        }

        element.gpsdateime = new Date(element.gpsdateime).toLocaleString();

        element.action = (
          <MoreVertIcon onClick={(event) => handleClick(event, element)} />
        );
      });
      setRows(vehicleData.result);
    }
  };

  useEffect(() => {
    getAllVehiclesDetails();
  }, []);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDisableEnableVehicle = async (id, isactive) => {
    const result = await VehicleServices.enableDisableLiveVehicle(id, isactive);
    if (result.status == true) {
      console.log("Vehicle Disabled Successfully Calling API");
      getAllVehiclesDetails();
    }
  };
  // const handleMenuItemClick = (action) => {
  //   if (selectedRow) {
  //     console.log("Action=>" + action);
  //     console.log("Selected Row" + JSON.stringify(selectedRow));
  //     if (action == "Disable") {
  //       handleDisableEnableVehicle(selectedRow.id, 0);
  //     }
  //     handleClose();
  //   }
  // };
  const handleMenuItemClick = (action) => {
    handleClose();
    if (action === "Disable") {
      setDialogQuestion("Are you sure you want to block this vehicle?");
    }
    if (action === "RemoveGPS") {
      setDialogQuestion(
        "Are you sure you want to remove GPS from this vehicle?"
      );
    }
    if (selectedRow) {
      setConfirmDialogOpen(true);
    }
  };
  const handleConfirmAction = () => {
    if (selectedRow) {
      console.log("Selected Row" + JSON.stringify(selectedRow));
      handleDisableEnableVehicle(selectedRow.id, 0);
    }
    setConfirmDialogOpen(false);

    setSelectedRow(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    columns.some((column) =>
      row[column.id]
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  );

  const renderProfileImage = (url, name) => {
    if (url) {
      return (
        <img
          src={
            "https://objectstorage.ap-hyderabad-1.oraclecloud.com/p/hZPWPOckWcDMqlptYrGkeqlmBDOoJmJBkmjeTMAUqKhePWPP6pJFX_iKs2GCAQ1s/n/axhsu9endsms/b/testbharatcars/o/bcp-images" +
            url
          }
          alt="Profile"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      );
    } else {
      return (
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            color: "#fff",
          }}
        >
          {name ? name.charAt(0).toUpperCase() : ""}
        </div>
      );
    }
  };

  const handleLinkClick = (type, id) => {
    console.log("Clicked" + type + "ID:" + id);
    const apiUrl = `http://10.2.2.144:3000/api/v1/get${type}Image/${id}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setImageUrl(data.imageUrl);
        setPopupOpen(true);
      });
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    setImageUrl("");
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        padding: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" id="tableTitle" component="div">
          Live Vehicles List
        </Typography>
        <TextField
          id="standard-basic"
          label="Search"
          variant="filled"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ height: "50px" }} // Decrease the height of the TextField
          InputProps={{ style: { height: "50px" } }} // Ensure the input field height is also decreased
        />
      </div>

      <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
        <TableContainer sx={{ maxHeight: 460 }}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No LiveVehicles Available
                  </TableCell>
                </TableRow>
              ) : (
                filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "profile" ? (
                                renderProfileImage(row.profilePicture, row.name)
                              ) : column.id === "aadhaar" ||
                                column.id === "dl" ? (
                                <a
                                  href="#"
                                  onClick={() =>
                                    handleLinkClick(column.id, row.id)
                                  }
                                >
                                  {value}
                                </a>
                              ) : column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleMenuItemClick("Disable")}>
          Block Vehicle
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("RemoveGPS")}>
          Remove GPS
        </MenuItem>
      </Menu>

      <Dialog open={popupOpen} onClose={handlePopupClose}>
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          <img src={imageUrl} alt="Document" style={{ width: "100%" }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePopupClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>{dialogQuestion}</DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default LiveVehicles;
