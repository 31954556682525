import { Alert } from "@mui/material"
import { useEffect, useState } from "react"

export default function CustomAlert(props){
    const [data,setData]=useState(props);
    useEffect(()=>{
        setData(props)
        setTimeout(()=>{
            setData()
        },5000)
    },[props])
    return(<>{data && <Alert severity={data.severity}>{data.message}</Alert>}</>)
}