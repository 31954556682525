import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAye-aDZx0UOP2cFFnwwZCQLmUPjWv9dxg",
  authDomain: "bharathcarsselfdrive.firebaseapp.com",
  projectId: "bharathcarsselfdrive",
  storageBucket: "bharathcarsselfdrive.firebasestorage.app",
  messagingSenderId: "719140334777",
  appId: "1:719140334777:web:1f30984bc223f8839c3f68",
  measurementId: "G-QWTR6HQYVV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Ensure the service worker is registered correctly
navigator.serviceWorker
  .register("/firebase-messaging-sw.js")
  .then((registration) => {
    messaging.useServiceWorker(registration);
  })
  .catch((error) => {
    console.error("Service Worker registration failed:", error);
  });

export const requestNotificationPermission = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey:
        "BJhFFbnD0hHzMc89NIwPj1_9YLZmYajWKKtkHBDNyrsnvJlUFSuhWsruOaB79pvuGRcDufzhMO9oJmR-sGKrxog",
    });
    if (token) {
      console.log("Notification permission granted.");
      return token;
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (error) {
    console.error("An error occurred while retrieving token. ", error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    onMessage(
      messaging,
      (payload) => {
        resolve(payload);
      },
      (error) => {
        reject(error);
      }
    );
  });

// Ensure that onMessageListener is called and handled properly in your main thread or component
// Example usage:
// onMessageListener().then(payload => {
//   // Handle the payload
// }).catch(err => console.error('Failed to receive message:', err));
