import CookiesServices from "./CookiesServices";

import HttpService from "./HttpService";
import axios from "axios";
import { apiEndpoint } from "../configuration";

let axiosConfig = {
  withCredentials: true,
};

class BookingServices {
  constructor() {
    const token = CookiesServices.get("token");
    token ? (this.authenticated = true) : (this.authenticated = false);
    this.http = new HttpService();
  }

  async getAllProtectionPlans() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/protectionplans`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async AddNewCoupons(coupon) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/offers`,
        coupon,
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getAllCoupons() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/offers/forweb`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async deActiveCoupon(id) {
    try {
      const response = await axios.put(
        `${apiEndpoint}/offers/` + id,
        { isactive: false },
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getBookingDetailsById(bookingid) {
    console.log("bookingid in Request:", bookingid);

    try {
      const response = await axios.get(
        `${apiEndpoint}/bookings/` + bookingid,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getPrePostCheckImages(bookingid, option) {
    console.log("bookingid in Request:", bookingid);
    console.log("option in Request:", option);
    try {
      const response = await axios.post(
        `${apiEndpoint}/documents/getDetails`,
        { bookingid, type: option },
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async sendLeaseAgreement(bookingid, option) {
    console.log("bookingid in Request:", bookingid);
    console.log("option in Request:", option);
    try {
      const response = await axios.post(
        `${apiEndpoint}/bookings/sla`,
        { bookingid, to: option },
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getLiveBookings() {
    try {
      const response = await axios.post(
        `${apiEndpoint}/bookings/live`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getPendingBookings() {
    try {
      const response = await axios.post(
        `${apiEndpoint}/bookings/pending`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async updateCashPayment(data) {
    console.log("data", JSON.stringify(data));
    try {
      const response = await axios.post(
        `${apiEndpoint}/bookings/cashconfirm`,
        data,
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getRejectedBookings() {
    try {
      const response = await axios.post(
        `${apiEndpoint}/bookings/rejected`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getCancelledBookings() {
    try {
      const response = await axios.post(
        `${apiEndpoint}/bookings/cancelled`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getAllFailedBookings() {
    try {
      const response = await axios.post(
        `${apiEndpoint}/bookings/failed`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getBookingsBasedOnDate(fromDateTime, toDateTime) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/bookings/history`,
        { fromDateTime, toDateTime },
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getLiveBookings1() {
    try {
      console.log("Cookies:>" + CookiesServices.get("token"));
      const response = await axios.post(`/bookings/live`, {});
      console.log("response", response);
      return response;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async createInvoice(data) {
    try {
      const response = await this.http.post(
        `/api/invoice/createinvoice`,
        data,
        axiosConfig
      );
      console.log("response", response);
      return response;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getInvoices(data) {
    try {
      const response = await this.http.post(
        `/api/invoice/getInvoices`,
        data,
        axiosConfig
      );
      console.log("response", response);
      return response;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getInvoiceDetails(invoicenumber) {
    try {
      const response = await this.http.get(
        `/api/invoice/details/${invoicenumber}`,
        axiosConfig
      );
      console.log("response", response);
      return response;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getDashboardDetails(data) {
    try {
      const response = await this.http.post(
        `/api/invoice/dashboard`,
        data,
        axiosConfig
      );
      console.log("response", response);
      return response;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
}

export default new BookingServices();
