import {
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Select,
  Autocomplete,
  MenuItem as DropdownMenuItem,
  FormControl,
  InputLabel,
  Grid2,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UserServices from "../../services/UserServices";
import SupportServices from "../../services/SupportServices";
import VehicleAdminServices from "../../services/VehicleAdminServices";

const columns = [
  { id: "id", label: "Sno", minWidth: 50 },
  { id: "model", label: "Model", minWidth: 100 },
  { id: "vehiclemake", label: "Make", minWidth: 150 },
  { id: "vehiclecategory", label: "Category", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];

const VehicleModel = () => {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleCategories, setVehicleCategories] = useState([]);
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedMakeId, setSelectedMakeId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [model, setModel] = useState("");
  const [makeError, setMakeError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [modelError, setModelError] = useState(false);

  const getVehicleModel = async () => {
    let usersData = await VehicleAdminServices.getAllVehicleModels();
    console.log("VehicleModel Data", JSON.stringify(usersData));
    if (usersData.status == true) {
      if (usersData.result.length > 0) {
        usersData.result.forEach((element) => {
          element.action = (
            <MoreVertIcon onClick={(event) => handleClick(event, element)} />
          );
        });
        setRows(usersData.result);
      }
    }
  };

  const getVehicleMakes = async () => {
    let makesData = await VehicleAdminServices.getAllVehicleMakes();
    if (makesData.status == true) {
      setVehicleMakes(makesData.result);
    }
  };

  const getVehicleCategories = async () => {
    let categoriesData = await VehicleAdminServices.getAllVehicleCategories();
    if (categoriesData.status == true) {
      setVehicleCategories(categoriesData.result);
    }
  };

  useEffect(() => {
    getVehicleModel();
    getVehicleMakes();
    getVehicleCategories();
  }, []);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuItemClick = (action) => {
    if (selectedRow) {
      console.log("Selected Row" + JSON.stringify(selectedRow));
      if (action == "Edit") {
        setSelectedMake(selectedRow.vehiclemake);
        setSelectedMakeId(selectedRow.vehiclemakeid);
        setSelectedCategory(selectedRow.vehiclecategory);
        setSelectedCategoryId(selectedRow.vehiclecategoryid);
        setModel(selectedRow.model);
        setEditDialogOpen(true);
        handleClose();
      } else if (action == "Delete") {
        // Add your delete logic here
        //Delete the Selected Row
        console.log("Delete Clicked");
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    columns.some((column) =>
      row[column.id]
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  );

  const renderProfileImage = (url, name) => {
    if (url) {
      return (
        <img
          src={
            "https://objectstorage.ap-hyderabad-1.oraclecloud.com/p/hZPWPOckWcDMqlptYrGkeqlmBDOoJmJBkmjeTMAUqKhePWPP6pJFX_iKs2GCAQ1s/n/axhsu9endsms/b/testbharatcars/o/bcp-images" +
            url
          }
          alt="Profile"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      );
    } else {
      return (
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            color: "#fff",
          }}
        >
          {name ? name.charAt(0).toUpperCase() : ""}
        </div>
      );
    }
  };

  const handleLinkClick = (type, id) => {
    console.log("Clicked" + type + "ID:" + id);
    const apiUrl = `http://10.2.2.144:3000/api/v1/get${type}Image/${id}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setImageUrl(data.imageUrl);
        setPopupOpen(true);
      });
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    setImageUrl("");
  };

  const handleAddButtonClick = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = () => {
    setAddDialogOpen(false);
    setSelectedMake("");
    setSelectedCategory("");
    setModel("");
    setMakeError(false);
    setCategoryError(false);
    setModelError(false);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedMake("");
    setSelectedCategory("");
    setModel("");
    setMakeError(false);
    setCategoryError(false);
    setModelError(false);
  };

  const handleSave = async () => {
    let valid = true;
    if (!selectedMake) {
      setMakeError(true);
      valid = false;
    } else {
      setMakeError(false);
    }

    if (!selectedCategory) {
      setCategoryError(true);
      valid = false;
    } else {
      setCategoryError(false);
    }

    if (!model) {
      setModelError(true);
      valid = false;
    } else {
      setModelError(false);
    }

    if (valid) {
      console.log(
        "Selected  Make:" +
          selectedMake +
          "Selected Cat:" +
          selectedCategory +
          "Model:" +
          model
      );

      // Add your save logic here
      const data = {
        model: model,
        vehiclecategoryid: selectedCategory,
        vehiclemakeid: selectedMake,
        url: "\\testing",
      };
      const result = await VehicleAdminServices.addVehicleModel(data);
      console.log("Result>>>>", JSON.stringify(result));
      if (result.status == true) {
        console.log("Vehicle Model Added Successfully");

        setAddDialogOpen(false);

        alert("Vehicle Model Added Successfully");
        getVehicleModel();
      } else {
        console.log("Error Adding Vehicle Model");
        alert("Error Adding Vehicle Model");
      }
    }
  };

  const handleUpdate = async () => {
    let valid = true;
    if (!selectedMake) {
      setMakeError(true);
      valid = false;
    } else {
      setMakeError(false);
    }

    if (!selectedCategory) {
      setCategoryError(true);
      valid = false;
    } else {
      setCategoryError(false);
    }

    if (!model) {
      setModelError(true);
      valid = false;
    } else {
      setModelError(false);
    }

    if (valid) {
      console.log(
        "Selected  Make:" +
          selectedMake +
          "Selected Cat:" +
          selectedCategory +
          "Model:" +
          model
      );

      // Add your update logic here
      const data = {
        id: selectedRow.id,
        model: model,
        vehiclecategoryid: selectedCategory,
        vehiclemakeid: selectedMake,
        url: "\\testing",
      };
      const result = await VehicleAdminServices.updateVehicleModel(data);
      console.log("Result>>>>", JSON.stringify(result));
      if (result.status == true) {
        console.log("Vehicle Model Updated Successfully");

        setEditDialogOpen(false);

        alert("Vehicle Model Updated Successfully");
        getVehicleModel();
      } else {
        console.log("Error Updating Vehicle Model");
        alert("Error Updating Vehicle Model");
      }
    }
  };

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          padding: 2,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" id="tableTitle" component="div">
            Vehicle Models
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddButtonClick}
              style={{ marginRight: "10px" }}
            >
              Add
            </Button>
            <TextField
              id="standard-basic"
              label="Search"
              variant="filled"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ height: "50px" }} // Decrease the height of the TextField
              InputProps={{ style: { height: "50px" } }} // Ensure the input field height is also decreased
            />
          </div>
        </div>

        <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
          <TableContainer sx={{ maxHeight: 460 }}>
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "#f4f4f4",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No VehicleModels Available
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "profile" ? (
                                  renderProfileImage(
                                    row.profilePicture,
                                    row.name
                                  )
                                ) : column.id === "aadhaar" ||
                                  column.id === "dl" ? (
                                  <a
                                    href="#"
                                    onClick={() =>
                                      handleLinkClick(column.id, row.id)
                                    }
                                  >
                                    {value}
                                  </a>
                                ) : column.format &&
                                  typeof value === "number" ? (
                                  column.format(value)
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleMenuItemClick("Edit")}>Edit</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Delete")}>
            Delete
          </MenuItem>
        </Menu>

        <Dialog open={popupOpen} onClose={handlePopupClose}>
          <DialogTitle>Image</DialogTitle>
          <DialogContent>
            <img src={imageUrl} alt="Document" style={{ width: "100%" }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePopupClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={addDialogOpen}
          onClose={handleAddDialogClose}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Add Vehicle Model</DialogTitle>
          <DialogContent>
            <Autocomplete
              style={{ marginBottom: 10 }}
              options={vehicleMakes}
              getOptionLabel={(option) => option.make}
              value={
                vehicleMakes.find((make) => make.id === selectedMake) || null
              }
              onChange={(_, newValue) => {
                setSelectedMake(newValue ? newValue.id : "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vehicle Make"
                  variant="filled"
                  error={makeError}
                  helperText={makeError ? "Please select a vehicle make" : ""}
                  InputProps={{ ...params.InputProps, readOnly: true }}
                />
              )}
            />

            <Autocomplete
              options={vehicleCategories}
              getOptionLabel={(option) => option.vehiclecategory}
              value={
                vehicleCategories.find(
                  (category) => category.id === selectedCategory
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedCategory(newValue ? newValue.id : "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vehicle Category"
                  variant="filled"
                  error={categoryError}
                  helperText={
                    categoryError ? "Please select a vehicle category" : ""
                  }
                />
              )}
            />

            <TextField
              id="model"
              label="Vehicle Model"
              variant="filled"
              value={model}
              onChange={(e) => setModel(e.target.value)}
              fullWidth
              style={{ marginTop: "20px" }}
              error={modelError}
              helperText={modelError ? "Please enter a vehicle model" : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={editDialogOpen}
          onClose={handleEditDialogClose}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Edit Vehicle Model</DialogTitle>
          <DialogContent>
            <TextField
              label="Vehicle Make"
              variant="filled"
              value={selectedMake}
              error={makeError}
              fullWidth
              sx={{ marginBottom: 2 }}
              helperText={makeError ? "Please select a vehicle make" : ""}
            />

            {/* <Autocomplete
              style={{ marginBottom: 10 }}
              options={vehicleMakes}
              getOptionLabel={(option) => option.make}
              value={
                vehicleMakes.find((make) => make.id === selectedMake) || null
              }
              onChange={(event, newValue) => {
                setSelectedMake(newValue ? newValue.id : "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vehicle Make"
                  variant="filled"
                  error={makeError}
                  helperText={makeError ? "Please select a vehicle make" : ""}
                />
              )}
            /> */}

            {/* <Autocomplete
              options={vehicleCategories}
              getOptionLabel={(option) => option.vehiclecategory}
              value={
                vehicleCategories.find(
                  (category) => category.id === selectedCategory
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedCategory(newValue ? newValue.id : "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vehicle Category"
                  variant="filled"
                  error={categoryError}
                  helperText={
                    categoryError ? "Please select a vehicle category" : ""
                  }
                />
              )}
            /> */}

            <TextField
              id="model"
              label="Vehicle Model"
              variant="filled"
              value={model}
              onChange={(e) => setModel(e.target.value)}
              fullWidth
              style={{ marginTop: "20px" }}
              error={modelError}
              helperText={modelError ? "Please enter a vehicle model" : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdate} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </>
  );
};

export default VehicleModel;
