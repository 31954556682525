//generate component for Live Bookings just display Message Dashboar at center of the page
import React from "react";
import { Typography, Grid, Grid2 } from "@mui/material";
import Select from "react-select";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";

import {
  GoogleMap,
  useLoadScript,
  Marker,
  MarkerF,
} from "@react-google-maps/api";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import Chip from "@mui/material/Chip";
import VehicleServices from "../../services/VehicleServices";
const containerStyle = {
  width: "100%",
  height: "500px",
};

// const center = {
//   lat: 12.971599,
//   lng: 77.594566,
// };
const Tracking = () => {
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [center, setCenter] = useState({ lat: 12.971599, lng: 77.594566 });
  const [isLive, setIsLive] = useState(false);
  const [selectedVehicleInfo, setSelectedVehicleInfo] = useState(null);
  const [markerPosition, setMarkerPosition] = useState({
    lat: 12.971599,
    lng: 77.594566,
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC3kUoMj1YyXsp24PnMhC_KLHWzQmau8aw",
  });
  const fetchVehicles = async () => {
    try {
      const vehicleData = await VehicleServices.getLiveVehiclesList();
      let data = [];
      if (vehicleData.status == true) {
        //vehicledata consists of vehicle number take all vehicle numbers and add to select drop down
        vehicleData.result.map((vehicle) => {
          data.push({ vehicleno: vehicle.vehicleno, id: vehicle.id });
        });
      }

      setVehicles(data);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };
  useEffect(() => {
    fetchVehicles();
  }, []);
  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  //generate code to do on loading of the page hit api to get all vehicles and display in select drop down, select drop down should be searchable also based on user typing it should filter out vehicle number

  //on selecting vehicle number from drop down hit api to get vehicle details and display on map

  const handleVehicleChange = async (event) => {
    const vehicle = event;
    console.log("Vehicle Id:" + vehicle);
    try {
      const response = await VehicleServices.getLocationOfVehicleId(vehicle);
      if (response.status == true) {
        console.log(response.result);
        if (response.result.length > 0) {
          console.log("Vehicle Number:" + response.result[0].gpsdatetime);
          //Generate code to check gpsdatetime and current time difference,
          //if difference is more than 5 minutes then show not working else show live
          const gpsDateTime = new Date(response.result[0].gpsdatetime);
          const currentTime = new Date();
          const diff =
            Math.abs(currentTime.getTime() - gpsDateTime.getTime()) / 60000;
          console.log("Difference in minutes:=>" + diff);
          if (diff > 60) {
            setIsLive(false);
          } else {
            setIsLive(true);
          }

          setSelectedVehicle(response.result[0].vehicleno);
        } else {
          setIsLive(false);
        }
        setSelectedVehicleInfo(response.result[0]);
        let data = response.result;
        // latitude, longitude from data and move the google map marker
        console.log("LATITUDE:" + data[0].latitude);
        const newCenter = {
          lat: data[0].latitude,
          lng: data[0].longitude,
        };
        setCenter(newCenter);
        setMarkerPosition(newCenter);
      }
      // console.log(center);

      //console.log(data);
      // You can update the state with the fetched data here
    } catch (error) {
      console.error("Error fetching vehicle data:", error);
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6" id="vehicleTitle">
          Tracking Vehicles {selectedVehicle ? selectedVehicle : ""}{" "}
          {isLive ? (
            <Chip
              color="success"
              size="small"
              label="Live"
              icon={<PlayCircleIcon />}
            />
          ) : (
            <Chip
              color="warning"
              size="small"
              label="Not Working"
              icon={<DoNotDisturbOnIcon />}
            />
          )}
        </Typography>
        <div id="detailsDiv">
          <Grid2 container spacing={2}>
            <Grid2 item xs={4}>
              <Typography style={{ fontSize: "12px" }}>
                Gps time:{" "}
                {selectedVehicleInfo
                  ? new Date(selectedVehicleInfo.gpsdatetime).toLocaleString(
                      "en-GB",
                      {
                        timeZone: "Asia/Kolkata",
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )
                  : ""}
              </Typography>
            </Grid2>
            <Grid2 item xs={4}>
              <Typography style={{ fontSize: "12px" }}>
                Ignition:{" "}
                {selectedVehicleInfo
                  ? selectedVehicleInfo.ignitionstatus == 1
                    ? "ON"
                    : "OFF"
                  : ""}
              </Typography>
            </Grid2>
            <Grid2 item xs={4}>
              <Typography style={{ fontSize: "12px" }}>
                Speed:{" "}
                {selectedVehicleInfo
                  ? selectedVehicleInfo.speed + " km/hr"
                  : ""}
              </Typography>
            </Grid2>
          </Grid2>
          <div>
            <Box>
              <Typography style={{ fontSize: "12px" }}>
                Location:{" "}
                {selectedVehicleInfo
                  ? selectedVehicleInfo.location.length > 100
                    ? selectedVehicleInfo.location.slice(0, 97) + "..."
                    : selectedVehicleInfo.location
                  : "Location not available"}
              </Typography>
            </Box>
          </div>
        </div>

        <Box width="300px" mr={2} id="dropdown">
          <Select
            options={vehicles.map((vehicle) => ({
              value: vehicle.id,
              label: vehicle.vehicleno,
            }))}
            onChange={(selectedOption) =>
              handleVehicleChange(selectedOption.value)
            }
            isSearchable
          />
        </Box>
      </Box>

      <Box display="flex" justifyContent="center">
        <Box width="100%">
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={18}
            >
              <MarkerF
                position={markerPosition}
                options={{
                  icon: {
                    url: "https://objectstorage.ap-hyderabad-1.oraclecloud.com/n/axhsu9endsms/b/testbharatcars/o/category%2Fhatchback.png", // Replace with your icon URL
                    scaledSize: new window.google.maps.Size(50, 50), // Set the size of the icon
                  },
                }}
              />
            </GoogleMap>
          )}
        </Box>
      </Box>
    </>
  );
};
export default Tracking;
