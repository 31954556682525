import React, { useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Typography,
  Avatar,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";

const users = [
  {
    id: 1,
    name: "John Doe",
    profilePicture:
      "https://objectstorage.ap-hyderabad-1.oraclecloud.com/p/hZPWPOckWcDMqlptYrGkeqlmBDOoJmJBkmjeTMAUqKhePWPP6pJFX_iKs2GCAQ1s/n/axhsu9endsms/b/testbharatcars/o/bcp-images/profile/pic/1724663382546.jpg",
    type: "Host",
    city: "Bangalore",
    lastChatTime: "10:00 AM",
  },
  {
    id: 2,
    name: "Jane Doe",
    type: "Customer",
    city: "Bangalore",
    lastChatTime: "9:30 AM",
  },
  {
    id: 3,
    name: "Bob Smith",
    profilePicture:
      "https://objectstorage.ap-hyderabad-1.oraclecloud.com/p/hZPWPOckWcDMqlptYrGkeqlmBDOoJmJBkmjeTMAUqKhePWPP6pJFX_iKs2GCAQ1s/n/axhsu9endsms/b/testbharatcars/o/bcp-images/profile/pic/1724663382546.jpg",
    type: "Customer",
    city: "Bangalore",
    lastChatTime: "9:00 AM",
  },
];

const messages = [
  { id: 1, text: "Hello!", sender: "John Doe", time: "10:00 AM" },
  { id: 2, text: "Hi!", sender: "You", time: "10:01 AM" },
  { id: 3, text: "How are you?", sender: "John Doe", time: "10:02 AM" },
  { id: 4, text: "I'm good.", sender: "You", time: "10:03 AM" },
];

const LiveChat = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [chatMessages, setChatMessages] = useState(messages);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  const handleSendMessage = () => {
    const newMessageObj = {
      id: chatMessages.length + 1,
      text: newMessage,
      sender: "You",
      time: new Date().toLocaleTimeString(),
    };
    setChatMessages([...chatMessages, newMessageObj]);
    setNewMessage("");
  };

  const getProfilePicture = (user) => {
    if (user.profilePicture) {
      return (
        <img
          src={user.profilePicture}
          alt={user.name}
          style={{
            width: "45px",
            height: "45px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      );
    } else {
      return (
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            color: "#fff",
          }}
        >
          {user.name.charAt(0).toUpperCase()}
        </div>
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={3}>
        <TextField
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search users"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ marginBottom: 2 }}
        />
        <List>
          {filteredUsers.map((user) => (
            <ListItem
              key={user.id}
              button
              onClick={() => handleUserSelect(user)}
            >
              <Avatar src={user.profilePicture} />
              <Box
                sx={{
                  marginLeft: 2,
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="h6" component="span">
                    {user.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {user.type} | {user.city}
                  </Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {user.lastChatTime}
                </Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={9}>
        {selectedUser && (
          <div style={{ marginLeft: 4 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 20,
                borderBottom: "1px solid #ccc",
                paddingBottom: 10,
              }}
            >
              <Avatar src={selectedUser.profilePicture} />
              <Box
                sx={{
                  marginLeft: 1,
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="h6" component="span">
                    {selectedUser.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ marginTop: -1 }}
                  >
                    {selectedUser.type} | {selectedUser.city}
                  </Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {selectedUser.lastChatTime}
                </Typography>
              </Box>
            </div>
            <div
              style={{
                height: "70vh",
                overflowY: "scroll",
                padding: 20,
                backgroundColor: "#f0f0f0",
              }}
            >
              {chatMessages.map((message) => (
                <div
                  key={message.id}
                  style={{
                    display: "flex",
                    justifyContent:
                      message.sender === "You" ? "flex-end" : "flex-start",
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      backgroundColor:
                        message.sender === "You" ? "#00800022" : "#ccc",
                      padding: 10,
                      borderRadius: 10,
                      color: message.sender === "You" ? "#008000" : "#333",
                    }}
                  >
                    <span style={{ fontSize: 14, color: "#666" }}>
                      {message.sender} - {message.time}
                    </span>
                    <br />
                    {message.text}
                  </div>
                </div>
              ))}
            </div>
            <Divider />
            <TextField
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type a message"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSendMessage}>
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default LiveChat;
