
import http from '../plugin/http';

class HttpService {
    constructor() {
        this.authorizedAxios =http;
    }
    get(url,data){
        console.log('get',url,this.authorizedAxios)
        return this.authorizedAxios.get(url)
        .then(res => {
            return res.data;
        }).catch(error=>{
            console.log('request error',error);
            return false;
        });
    }
    post(url,data){
        console.log('poost',url,data);
        return this.authorizedAxios.post(url, data)
            .then(res => {
                console.log(`${url} ${res}`)
                return res.data;
            }).catch(err=>{
                console.log('error',err);
                return false;
            });
    }
    delete(url,data){
        return this.authorizedAxios.delete(`${url}/${data}`)
        .then(res => {
            console.log(`${url} ${res}`)
            return res.data;
        }).catch(err=>{
            console.log('error',err);
            return false;
        });
    }
}

export default  HttpService;