import CookiesServices from "./CookiesServices";

import HttpService from "./HttpService";
import axios from "axios";
import { apiEndpoint } from "../configuration";

let axiosConfig = {
  withCredentials: true,
};

class HostServices {
  constructor() {
    const token = CookiesServices.get("token");
    token ? (this.authenticated = true) : (this.authenticated = false);
    this.http = new HttpService();
  }
  async getHostPricing() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/hostpricing`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getAllHostPayouts() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/hostpayouts`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async updateRatePerHour(id, rateperhour) {
    try {
      const response = await axios.put(
        `${apiEndpoint}/hostpricing/` + id,
        { rateperhour },
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
}

export default new HostServices();
