import {
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import UserServices from "../../services/UserServices";

import MoreVertIcon from "@mui/icons-material/MoreVert";

const columns = [
  { id: "id", label: "Sno", minWidth: 50 },
  { id: "profile", label: "Profile", minWidth: 100 },
  { id: "name", label: "Name", minWidth: 150 },
  { id: "mobile", label: "Mobile", minWidth: 100 },
  { id: "isMobileVerified", label: "Mobile Verified", minWidth: 125 },
  { id: "mobileOTP", label: "Mobile OTP", minWidth: 110 },
  {
    id: "mobileOtpgeneratedDatetime",
    label: "Mobile OTP Generated",
    minWidth: 180,
  },
  { id: "mobileOtpValid", label: "isMobile OTP", minWidth: 120 },

  { id: "email", label: "Email", minWidth: 170 },

  { id: "isEmailVerified", label: "Email Verified", minWidth: 120 },
  { id: "emailOTP", label: "Email OTP", minWidth: 100 },
  {
    id: "emailOtpGeneratedDatetime",
    label: "Email OTP Generated",
    minWidth: 180,
  },
  { id: "emailOtpValid", label: "isEmail OTP", minWidth: 120 },
  // { id: "profilePicture", label: "Profile Pic", minWidth: 100 },
  { id: "aadhaar", label: "Aadhar No", minWidth: 100 },
  { id: "isAadharVerified", label: "Verified Aadhar", minWidth: 150 },
  { id: "dl", label: "Driving License", minWidth: 100 },
  { id: "isDlVerified", label: "Verified DL", minWidth: 150 },
  { id: "isActive", label: "isActive", minWidth: 100 },
  { id: "latitude", label: "Latitude", minWidth: 100 },
  { id: "longitude", label: "Longitude", minWidth: 100 },
  { id: "locality", label: "Locality", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];

const AllHosts = () => {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const getAllHosts = async () => {
    let usersData = await UserServices.getAllHosts();
    if (usersData.status == true) {
      usersData.result.forEach((element) => {
        element.mobile = element.mobile.replace(
          /^(\d{2})\d{5}(\d{3})$/,
          "$1*****$2"
        );

        element.email = element.email.replace(
          /(?<=.{3}).*(?=@)/g,
          "*".repeat(5)
        );

        element.mobileOtpgeneratedDatetime = new Date(
          element.mobileOtpgeneratedDatetime
        ).toLocaleString();
        if (
          new Date(element.mobileOtpgeneratedDatetime).getTime() +
            5 * 60 * 1000 >
            new Date().getTime() ||
          new Date(element.mobileOtpgeneratedDatetime).getTime() >
            new Date().getTime()
        ) {
          element.mobileOtpValid = "Valid";
        } else {
          element.mobileOtpValid = "Expired";
        }

        element.emailOtpGeneratedDatetime = new Date(
          element.emailOtpGeneratedDatetime
        ).toLocaleString();
        if (
          new Date(element.emailOtpGeneratedDatetime).getTime() +
            5 * 60 * 1000 >
            new Date().getTime() ||
          new Date(element.emailOtpGeneratedDatetime).getTime() >
            new Date().getTime()
        ) {
          element.emailOtpValid = "Valid";
        } else {
          element.emailOtpValid = "Expired";
        }

        if (element.isMobileVerified == true) {
          element.isMobileVerified = "Verified";
        } else {
          element.isMobileVerified = "Not Verified";
        }

        if (element.isEmailVerified == true) {
          element.isEmailVerified = "Verified";
        } else {
          element.isEmailVerified = "Not Verified";
        }

        if (element.isAadharVerified == true) {
          element.isAadharVerified = "Verified";
        } else {
          element.isAadharVerified = "Not Verified";
        }
        if (element.isDlVerified == true) {
          element.isDlVerified = "Verified";
        } else {
          element.isDlVerified = "Not Verified";
        }
        if (element.isActive == true) {
          element.isActive = "Active";
        } else {
          element.isActive = "Deactivated";
        }

        element.action = (
          <MoreVertIcon onClick={(event) => handleClick(event, element)} />
        );
      });
      setRows(usersData.result);
    }
  };

  useEffect(() => {
    getAllHosts();
  }, []);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuItemClick = (action) => {
    if (selectedRow) {
      console.log("Selected Row" + selectedRow);
      const apiUrl = `http://10.2.2.144:3000/api/v1/${action.toLowerCase()}`;
      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mobile: selectedRow.mobile }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          handleClose();
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    columns.some((column) =>
      row[column.id]
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  );

  const renderProfileImage = (url, name) => {
    if (url) {
      return (
        <img
          src={url}
          alt="Profile"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      );
    } else {
      return (
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            color: "#fff",
          }}
        >
          {name ? name.charAt(0).toUpperCase() : ""}
        </div>
      );
    }
  };

  const handleLinkClick = (type, id) => {
    console.log("Clicked" + type + "ID:" + id);
    const apiUrl = `http://10.2.2.144:3000/api/v1/get${type}Image/${id}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setImageUrl(data.imageUrl);
        setPopupOpen(true);
      });
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    setImageUrl("");
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        padding: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" id="tableTitle" component="div">
          All Hosts
        </Typography>
        <TextField
          id="standard-basic"
          label="Search"
          variant="filled"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ height: "50px" }} // Decrease the height of the TextField
          InputProps={{ style: { height: "50px" } }} // Ensure the input field height is also decreased
        />
      </div>

      <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
        <TableContainer sx={{ maxHeight: 460 }}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "profile" ? (
                              renderProfileImage(row.profilePicture, row.name)
                            ) : column.id === "aadhaar" ||
                              column.id === "dl" ? (
                              <a
                                href="#"
                                onClick={() =>
                                  handleLinkClick(column.id, row.id)
                                }
                              >
                                {value}
                              </a>
                            ) : column.format && typeof value === "number" ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleMenuItemClick("SendViaSMS")}>
          View Vehicles
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("GenerateOtp")}>
          Generate mobile Otp
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("SendViaWhatsApp")}>
          Generate Email Otp
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("SendViaSMS")}>
          Deactivate Host
        </MenuItem>
      </Menu>

      <Dialog open={popupOpen} onClose={handlePopupClose}>
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          <img src={imageUrl} alt="Document" style={{ width: "100%" }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePopupClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default AllHosts;
