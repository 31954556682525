import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";
import cookiesService from "./services/CookiesServices";
import localStorageObj from "./services/LocalStorageService";
import { apiEndpoint } from "./configuration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import * as serviceWorkerRegistration from "../src/serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));
const allowedApisWithoutToken = [
  "/adminusers/verify",
  // Add more public endpoints as needed
];
axios.interceptors.request.use(
  async function (config) {
    console.log("axios interceptors");

    const isAllowedApi = allowedApisWithoutToken.some((api) =>
      config.url.includes(api)
    );
    console.log("isAllowedApi", isAllowedApi);
    if (!isAllowedApi) {
      try {
        let token = await cookiesService.get("token");
        console.log("Token we got:>" + token);
        config.headers["Authorization"] = "Bearer " + token;
      } catch (error) {
        console.error("Error getting token:", error);
        return Promise.reject(error);
      }
    }

    return config;
  },
  function (error) {
    // Do something with request error
    console.error("Request interceptor error:", error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Do something with successful response
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
