import React from "react";
import { Typography } from "@mui/material";
const Refunds = () => {
  return (
    <>
      <Typography variant="h4">Refunds</Typography>
    </>
  );
};
export default Refunds;
