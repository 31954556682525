import {
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import UserServices from "../../services/UserServices";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import HostServices from "../../services/HostServices";

const columns = [
  { id: "id", label: "Sno", minWidth: 50 },

  { id: "host", label: "Host", minWidth: 150 },

  { id: "vehicle", label: "Vehicle", minWidth: 125 },
  { id: "ratePerHour", label: "Rate Per Hour", minWidth: 110 },
  {
    id: "baseHours",
    label: "Base Hours",
    minWidth: 100,
  },
  { id: "extraRatePerHour", label: "Extra Rate Per Hour", minWidth: 120 },

  { id: "day", label: "Days Available", minWidth: 170 },

  { id: "status", label: "Status", minWidth: 120 },

  { id: "action", label: "Action", minWidth: 100 },
];

const HostVehicePricing = () => {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [ratePerHour, setRatePerHour] = useState("");
  const [rateError, setRateError] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const getAllHosts = async () => {
    let usersData = await HostServices.getHostPricing();
    if (usersData.status == true) {
      usersData.result.forEach((element) => {
        if (element.status == 1) {
          element.status = "Active";
        } else {
          element.status = "Deactivated";
        }

        element.action = (
          <MoreVertIcon onClick={(event) => handleClick(event, element)} />
        );
      });
      setRows(usersData.result);
    }
  };

  useEffect(() => {
    getAllHosts();
  }, []);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuItemClick = (action) => {
    setAnchorEl(null);
    if (selectedRow) {
      console.log("Selected Row" + selectedRow);
      if (action == "updateRatePerHour") {
        setUpdateDialogOpen(true);
      }
    }
  };

  const handleUpdateRatePerHour = async () => {
    if (!ratePerHour) {
      setRateError(true);
      return;
    }
    setRateError(false);

    setUpdateDialogOpen(false);
    try {
      const response = await HostServices.updateRatePerHour(
        selectedRow.id,
        ratePerHour
      );
      if (response.status === true) {
        alert("Rate per hour updated successfully");
        getAllHosts();

        setRatePerHour("");
      } else {
        alert("Failed to update rate per hour");
      }
    } catch (error) {
      console.error("Failed to update rate per hour", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    columns.some((column) =>
      row[column.id]
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  );

  const renderProfileImage = (url, name) => {
    if (url) {
      return (
        <img
          src={url}
          alt="Profile"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      );
    } else {
      return (
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            color: "#fff",
          }}
        >
          {name ? name.charAt(0).toUpperCase() : ""}
        </div>
      );
    }
  };

  const handleLinkClick = (type, id) => {
    console.log("Clicked" + type + "ID:" + id);
    const apiUrl = `http://10.2.2.144:3000/api/v1/get${type}Image/${id}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setImageUrl(data.imageUrl);
        setPopupOpen(true);
      });
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    setImageUrl("");
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        padding: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" id="tableTitle" component="div">
          Host Vehicle Pricing
        </Typography>
        <TextField
          id="standard-basic"
          label="Search"
          variant="filled"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ height: "50px" }} // Decrease the height of the TextField
          InputProps={{ style: { height: "50px" } }} // Ensure the input field height is also decreased
        />
      </div>

      <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
        <TableContainer sx={{ maxHeight: 460 }}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "profile" ? (
                              renderProfileImage(row.profilePicture, row.name)
                            ) : column.id === "aadhaar" ||
                              column.id === "dl" ? (
                              <a
                                href="#"
                                onClick={() =>
                                  handleLinkClick(column.id, row.id)
                                }
                              >
                                {value}
                              </a>
                            ) : column.format && typeof value === "number" ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleMenuItemClick("updateRatePerHour")}>
          Update Rate/Hr
        </MenuItem>
      </Menu>

      <Dialog open={popupOpen} onClose={handlePopupClose}>
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          <img src={imageUrl} alt="Document" style={{ width: "100%" }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePopupClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
      >
        <DialogTitle>Update Rate Per Hour</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Rate Per Hour"
            type="number"
            fullWidth
            variant="standard"
            value={ratePerHour}
            onChange={(e) => setRatePerHour(e.target.value)}
            error={rateError}
            helperText={rateError ? "Rate per hour is required" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateRatePerHour} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default HostVehicePricing;
