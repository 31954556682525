import {
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ExpandMore } from "@mui/icons-material";
import UserServices from "../../services/UserServices";
import SupportServices from "../../services/SupportServices";
import VehicleAdminServices from "../../services/VehicleAdminServices";

const columns = [
  { id: "id", label: "Sno", minWidth: 50 },
  { id: "typeName", label: "Fuel Type", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];

const VehicleFuelTypes = () => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [typeName, setTypeName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getVehicleFuelTypes();
  }, []);

  const getVehicleFuelTypes = async () => {
    let usersData = await VehicleAdminServices.getAllVehicleFuelTypes();
    if (usersData.status) {
      usersData.result.forEach((element) => {
        element.action = (
          <MoreVertIcon onClick={(event) => handleClick(event, element)} />
        );
      });
      setRows(usersData.result);
    }
  };

  const handleSave = async () => {
    await VehicleAdminServices.saveVehicleFuelType({ typeName });
    getVehicleFuelTypes(); // Refresh the list after saving
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuItemClick = (action) => {
    if (selectedRow) {
      const apiUrl = `http://10.2.2.144:3000/api/v1/${action.toLowerCase()}`;
      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mobile: selectedRow.mobile }),
      })
        .then((response) => response.json())
        .then((data) => {
          handleClose();
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /**
   * Handles the change in the number of rows per page.
   *
   * @param {Object} event - The event object from the change event.
   * @param {Object} event.target - The target element of the event.
   * @param {string} event.target.value - The new number of rows per page.
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleLinkClick = () => {
    console.log("Link clicked");
  };
  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  /**
   * Handles the change event for the search input field.
   *
   * @param {Object} event - The event object from the input field.
   * @param {Object} event.target - The target element of the event.
   * @param {string} event.target.value - The current value of the input field.
   */
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  /**
   * Filters the rows based on the search term.
   *
   * @param {Array<Object>} rows - The array of row objects to be filtered.
   * @param {Array<Object>} columns - The array of column objects, each containing an `id` property.
   * @param {string} searchTerm - The term to search for within the rows.
   * @returns {Array<Object>} The filtered array of row objects where at least one column's value includes the search term.
   */
  const filteredRows = rows.filter((row) =>
    columns.some((column) =>
      row[column.id]
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", padding: 2 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            id="fuel-type"
            label="Fuel Type"
            variant="filled"
            value={typeName}
            onChange={(e) => setTypeName(e.target.value)}
            sx={{ height: "50px" }}
            InputProps={{ style: { height: "50px" } }}
          />
          <Button variant="contained" color="primary" onClick={handleSave}>
            Add
          </Button>
          <TextField
            id="standard-basic"
            label="Search"
            variant="filled"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ height: "50px" }}
            InputProps={{ style: { height: "50px" } }}
          />
        </div>
        <VehicleFuelTypesTable
          columns={columns}
          rows={filteredRows}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleLinkClick={handleLinkClick}
        />
        <ActionMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleMenuItemClick={handleMenuItemClick}
        />
        <ImageDialog
          popupOpen={popupOpen}
          handlePopupClose={handlePopupClose}
          imageUrl={imageUrl}
        />
      </Paper>
    </>
  );
};

const VehicleFuelTypesTable = ({
  columns,
  rows,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleLinkClick,
}) => {
  const renderProfileImage = (url, name) => {
    if (url) {
      return (
        <img
          src={
            "https://objectstorage.ap-hyderabad-1.oraclecloud.com/p/hZPWPOckWcDMqlptYrGkeqlmBDOoJmJBkmjeTMAUqKhePWPP6pJFX_iKs2GCAQ1s/n/axhsu9endsms/b/testbharatcars/o/bcp-images" +
            url
          }
          alt="Profile"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      );
    } else {
      return (
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            color: "#fff",
          }}
        >
          {name ? name.charAt(0).toUpperCase() : ""}
        </div>
      );
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
      <TableContainer sx={{ maxHeight: 460 }}>
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#f4f4f4",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No VehicleFuelTypes Available
                </TableCell>
              </TableRow>
            ) : (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "profile" ? (
                            renderProfileImage(row.profilePicture, row.name)
                          ) : column.id === "aadhaar" || column.id === "dl" ? (
                            <a
                              href="#"
                              onClick={() => handleLinkClick(column.id, row.id)}
                            >
                              {value}
                            </a>
                          ) : column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

const ActionMenu = ({ anchorEl, handleClose, handleMenuItemClick }) => (
  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
    <MenuItem onClick={() => handleMenuItemClick("AssignToCustomer")}>
      Assign to Customer
    </MenuItem>
  </Menu>
);

const ImageDialog = ({ popupOpen, handlePopupClose, imageUrl }) => (
  <Dialog open={popupOpen} onClose={handlePopupClose}>
    <DialogTitle>Image</DialogTitle>
    <DialogContent>
      <img src={imageUrl} alt="Document" style={{ width: "100%" }} />
    </DialogContent>
    <DialogActions>
      <Button onClick={handlePopupClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default VehicleFuelTypes;
