import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputLabel,
  FormControl,
} from "@mui/material";

const Complaints = () => {
  const [complaints, setComplaints] = useState([]);
  const [complaint, setComplaint] = useState({
    userName: "",
    mobileNumber: "",
    complaintType: "",
    details: "",
  });
  const [id, setId] = useState(1);

  const handleComplaintChange = (e) => {
    setComplaint({ ...complaint, [e.target.name]: e.target.value });
  };

  const handleRegisterComplaint = () => {
    if (
      complaint.userName &&
      complaint.mobileNumber &&
      complaint.complaintType &&
      complaint.details
    ) {
      setComplaints([...complaints, { id, ...complaint, status: "Pending" }]);
      setComplaint({
        userName: "",
        mobileNumber: "",
        complaintType: "",
        details: "",
      });
      setId(id + 1);
    } else {
      alert("Please fill all fields");
    }
  };

  const handleStatusChange = (id, status) => {
    const updatedComplaints = complaints.map((complaint) =>
      complaint.id === id ? { ...complaint, status } : complaint
    );
    setComplaints(updatedComplaints);
  };

  const handleDelete = (id) => {
    setComplaints(complaints.filter((complaint) => complaint.id !== id));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2>Register Complaint</h2>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  label="User Name"
                  name="userName"
                  value={complaint.userName}
                  onChange={handleComplaintChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Mobile Number"
                  size="small"
                  name="mobileNumber"
                  value={complaint.mobileNumber}
                  onChange={handleComplaintChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth size="small">
                  <InputLabel style={{ color: "blue" }}>
                    Complaint Type
                  </InputLabel>
                  <Select
                    name="complaintType"
                    value={complaint.complaintType}
                    onChange={handleComplaintChange}
                  >
                    <MenuItem value="Vehicle">Vehicle</MenuItem>
                    <MenuItem value="Host">Host</MenuItem>
                    <MenuItem value="Customer">Customer</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Details of Complaint"
              name="details"
              value={complaint.details}
              onChange={handleComplaintChange}
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleRegisterComplaint}>
              Register Complaint
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h2>Complaints List</h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Info</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {complaints.map((complaint) => (
              <TableRow key={complaint.id}>
                <TableCell>
                  {complaint.userName} ({complaint.mobileNumber}) -{" "}
                  {complaint.complaintType}
                </TableCell>
                <TableCell>{complaint.details}</TableCell>
                <TableCell>
                  <Select
                    value={complaint.status || "Pending"}
                    onChange={(e) =>
                      handleStatusChange(complaint.id, e.target.value)
                    }
                  >
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="In Progress">In Progress</MenuItem>
                    <MenuItem value="Resolved">Resolved</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  <Button variant="contained" color="primary">
                    View
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDelete(complaint.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default Complaints;
