import {
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import DownloadIcon from "@mui/icons-material/Download";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BookingServices from "../../services/BookingServices";
import HostServices from "../../services/HostServices";

const columns = [
  { id: "id", label: "Sno", minWidth: 25 },
  { id: "hostid", label: "Host Id", minWidth: 80 },
  { id: "host", label: "Host", minWidth: 150 },

  { id: "bookingid", label: "Booking ID", minWidth: 150 },
  { id: "bookingno", label: "Booking No", minWidth: 180 },
  { id: "bookingstatusid", label: "Booking Status id", minWidth: 180 },
  {
    id: "bookingstatus",
    label: "Booking Status",
    minWidth: 180,
  },
  { id: "totalamount", label: "Total Amount", minWidth: 150 },

  { id: "payduedate", label: "Pay Due Date", minWidth: 180 },
  { id: "statusid", label: "Status Id", minWidth: 120 },
  { id: "paymentstatus", label: "Payment Status", minWidth: 150 },
  { id: "payoutamount", label: "Payout Amount", minWidth: 150 },

  { id: "transactionid", label: "Transaction Id", minWidth: 180 },
  {
    id: "payoutdate",
    label: "Payout Date",
    minWidth: 180,
  },
  { id: "paidvia", label: "Paid Via ", minWidth: 130 },

  { id: "remarks", label: "Remarks", minWidth: 280 },

  // { id: "profilePicture", label: "Profile Pic", minWidth: 100 },

  { id: "paiduserid", label: "PaidUserId", minWidth: 150 },

  { id: "action", label: "Action", minWidth: 100 },
];

const HostPayouts = () => {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    payoutAmount: "",
    transactionId: "",
    payoutDate: "",
    paidVia: "",
    remarks: "",
  });

  useEffect(() => {
    //getAllLiveBookings();
    getAllHostPayouts();
    // let bookingData = await BookingServices.getLiveBookings();
    // console.log("Booking Data", bookingData);
    //setRows(data.result);
  }, []);

  const handleGetBookingsBasedOnDate = async () => {
    const fromDateTime = document.getElementById("from-datetime").value;
    const toDateTime = document.getElementById("to-datetime").value;
    console.log("From Date Time" + fromDateTime);
    console.log("To Date Time" + toDateTime);

    let bookingDate = await BookingServices.getBookingsBasedOnDate(
      fromDateTime,
      toDateTime
    );
    console.log("Booking Data", bookingDate);
    if (bookingDate.status == true) {
      //generate code in bookingData.result parse each element and change the date formats
      if (bookingDate.result.length === 0) {
        setRows([]);
      } else {
        bookingDate.result.forEach((element) => {
          element.plannedstartdatetime = new Date(
            element.plannedstartdatetime
          ).toLocaleString();
          element.plannedenddatetime = new Date(
            element.plannedenddatetime
          ).toLocaleString();
          element.leaseagreementurl = (
            <a href={element.leaseagreementurl} download>
              <DownloadIcon />
            </a>
          );
          element.invoiceurl =
            element.bookingstatusid === 14 ? (
              <a href={element.invoiceurl} download>
                <DownloadIcon />
              </a>
            ) : null;
          // element.action =
          //   element.statusid == 0 ? (
          //     {element.statusid !== 1 && (
          //       <MoreVertIcon onClick={(event) => handleClick(event, element)} />
          //     )}
          //   ) : null;
        });
        setRows(bookingDate.result);
      }
      setRows(bookingDate.result);
    }
  };

  const getAllHostPayouts = async () => {
    let hostPayoutsData = await HostServices.getAllHostPayouts();
    console.log("Host Payouts =>", hostPayoutsData);
    if (hostPayoutsData.status == true) {
      //generate code in bookingData.result parse each element and change the date formats
      hostPayoutsData.result.forEach((element) => {
        element.payoutdate = new Date(
          element.payoutdate ? element.payoutdate : ""
        ).toLocaleString();

        element.payduedate = new Date(element.payduedate).toLocaleString();
        if (element.statusid == 0) {
          element.action = (
            <MoreVertIcon onClick={(event) => handleClick(event, element)} />
          );
        }
      });
      setRows(hostPayoutsData.result);
    }
  };
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuItemClick = (action) => {
    if (action == "PayNow") {
      setPaymentDialogOpen(true);
    }
    if (selectedRow) {
      console.log("Selected Row=>" + selectedRow);
    }
  };

  const handlePaymentDetailChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handlePaymentDialogClose = () => {
    setPaymentDialogOpen(false);
    setPaymentDetails({
      payoutAmount: "",
      transactionId: "",
      payoutDate: "",
      paidVia: "",
      remarks: "",
    });
  };

  const handlePaymentSave = async () => {
    console.log("Payment Details Saved", paymentDetails);
    // Add your save logic here
    if (selectedRow) {
      const response = await HostServices.savePaymentDetails({
        ...paymentDetails,
        hostId: selectedRow.hostid,
      });
      if (response.status === true) {
        console.log("Payment details saved successfully");
        getAllHostPayouts(); // Refresh the data
      } else {
        console.error("Failed to save payment details");
      }
    }
    handlePaymentDialogClose();
    handleClose(); // Close the menu
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    columns.some((column) =>
      row[column.id]
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  );

  const renderProfileImage = (url, name) => {
    if (url) {
      return (
        <img
          src={
            "https://objectstorage.ap-hyderabad-1.oraclecloud.com/p/hZPWPOckWcDMqlptYrGkeqlmBDOoJmJBkmjeTMAUqKhePWPP6pJFX_iKs2GCAQ1s/n/axhsu9endsms/b/testbharatcars/o/bcp-images" +
            url
          }
          alt="Profile"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      );
    } else {
      return (
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            color: "#fff",
          }}
        >
          {name ? name.charAt(0).toUpperCase() : ""}
        </div>
      );
    }
  };

  const handleLinkClick = (type, id) => {
    console.log("Clicked" + type + "ID:" + id);
    const apiUrl = `http://10.2.2.144:3000/api/v1/get${type}Image/${id}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setImageUrl(data.imageUrl);
        setPopupOpen(true);
      });
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    setImageUrl("");
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        padding: 2,
        marginLeft: 2, // Add left margin
        marginRight: 2, // Add right margin
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: 2, // Add left margin
          marginRight: 2, // Add right margin
        }}
      >
        <Typography variant="h6" id="tableTitle" component="div">
          Host Payouts
        </Typography>
        <div style={{ display: "flex", alignItems: "right" }}>
          <TextField
            id="from-datetime"
            label="From Date Time"
            type="datetime-local"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            sx={{ marginLeft: 2, marginRight: 2 }} // Add left and right margins
          />
          <TextField
            id="to-datetime"
            label="To Date Time"
            type="datetime-local"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            // Add left and right margins
          />

          <Button
            variant="contained"
            color="primary"
            // onClick={handleGetBookingsBasedOnDate}
          >
            Get Bookings
          </Button>
          <TextField
            id="standard-basic"
            label="Search"
            variant="filled"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ height: "50px", marginLeft: 2, marginRight: 2 }} // Add left and right margins and decrease the height of the TextField
            InputProps={{ style: { height: "50px" } }} // Ensure the input field height is also decreased
          />
        </div>
      </div>

      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          padding: 1,
          marginLeft: 2,
          marginRight: 2,
        }}
      >
        <TableContainer sx={{ maxHeight: 460 }}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No Host Payouts Available
                  </TableCell>
                </TableRow>
              ) : (
                filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "profile" ? (
                                renderProfileImage(row.profilePicture, row.name)
                              ) : column.id === "aadhaar" ||
                                column.id === "dl" ? (
                                <a
                                  href="#"
                                  onClick={() =>
                                    handleLinkClick(column.id, row.id)
                                  }
                                >
                                  {value}
                                </a>
                              ) : column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleMenuItemClick("PayNow")}>
          Pay Now
        </MenuItem>
      </Menu>

      <Dialog open={popupOpen} onClose={handlePopupClose}>
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          <img src={imageUrl} alt="Document" style={{ width: "100%" }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePopupClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={paymentDialogOpen} onClose={handlePaymentDialogClose}>
        <DialogTitle>Enter Payment Details</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Payout Amount"
            type="number"
            fullWidth
            name="payoutAmount"
            value={paymentDetails.payoutAmount}
            onChange={handlePaymentDetailChange}
          />
          <TextField
            margin="dense"
            label="Transaction ID"
            type="text"
            fullWidth
            name="transactionId"
            value={paymentDetails.transactionId}
            onChange={handlePaymentDetailChange}
          />
          <TextField
            margin="dense"
            label="Payout Date"
            type="datetime-local"
            fullWidth
            name="payoutDate"
            value={paymentDetails.payoutDate}
            onChange={handlePaymentDetailChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            label="Paid Via"
            type="text"
            fullWidth
            name="paidVia"
            value={paymentDetails.paidVia}
            onChange={handlePaymentDetailChange}
          />
          <TextField
            margin="dense"
            label="Remarks"
            type="text"
            fullWidth
            name="remarks"
            value={paymentDetails.remarks}
            onChange={handlePaymentDetailChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePaymentDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePaymentSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default HostPayouts;
