import React from "react";
import { Typography } from "@mui/material";
const HostEarnings = () => {
  return (
    <>
      <Typography variant="h4">HostEarnings</Typography>
    </>
  );
};
export default HostEarnings;
